//
// Media Objects custom
// --------------------------------------------------

.media-right,
.media > .pull-right {
  padding-left: 10px;
}

.media-left,
.media > .pull-left {
  padding-right: 10px;
}

@media (min-width: $screen-xs-min) {
  .media-right,
  .media > .pull-right {
    padding-left: 20px;
  }

  .media-left,
  .media > .pull-left {
    padding-right: 20px;
  }
}
//
// Search Results
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.search_list{
  text-align: left;
  padding-left: 0;
  font-size: $rd-search-list-size;
  list-style-type: none;
}

.result-item{
  padding-left: $rd-result-item-padding-left;
  list-style-type: decimal;
  margin-left: $rd-result-item-offset-left;
  color: $rd-result-item-color;
  >*{
    color: $rd-result-item-inside-color;
  }
  p{
    font-size: $font-size-base;
  }

  +.result-item{
    margin-top: $rd-result-item-offset-top-between;
  }
  @media (min-width: $screen-sm-min) {
    padding-left: $rd-result-item-sm-padding-left;
  }
}

.search_title{
  color: $rd-search-title-color;
}

.match{
  font-size: $rd-search-math-size;
  text-transform: uppercase;
  letter-spacing: $rd-search-math-letter-spacing;
  color: $rd-search-math-color;
  em{
    font-style: normal;
  }
}

.search{
  background: $rd-search-background;
}
// Labels

@mixin label-variant($color) {
  background-color: $color;

  &[href] {
    &:hover,
    &:focus {
      background-color: lighten($color, 5%);
    }
  }
}

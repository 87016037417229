//
// RD Parallax
// --------------------------------------------------

.rd-parallax{
  &-inner{
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    z-index: 1;
    clip: rect(0,auto,auto,0);
  }

  &-layer[data-type="media"]{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    pointer-events: none;

    iframe{
      width: 100%;
      height: 100%;
    }
  }

  &-layer[data-url]{
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
  }

  .rd-parallax-layer[data-type="media"]{
    & + *{
      position: relative;
    }
  }
}
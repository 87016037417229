//
// Pricing and Plans
// --------------------------------------------------


// Base styles
// --------------------------------------------------

// Box Pricing
// -------------------------

.box-pricing {
  text-align: left;

  &-title {
    @include display-flex();
    @include flex(0 1 100%);
    @include flex-direction(row);
    @include flex-wrap(nowrap);

    > *, &:after {
      @include flex(0 1 auto);
      @include flex-grow(0);
    }

    .box-pricing-price {
      margin-top: 0;
    }

    .box-pricing-name {
      padding-top: 5px;
    }

    .box-pricing-dots {
      position: relative;
      @include flex-grow(1);
      top: -6px;
      left: -2px;
      //content: '';
      border-bottom: 2px dotted $gray-darker;
    }
  }

  // List offsets

  li + li {
    margin-top: 23px;
  }
}

// Box Planning
// -------------------------

.box-planning {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 11%;

  &-label {
    display: none;
    z-index: 2;
    color: $white;

    span {
      position: absolute;
    }
  }

  // Reset default
  h1, .h1 {
    margin-bottom: 0;
    margin-top: 0;
  }

  h2, .h2 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .small {
    margin-top: 0;
    font-size: 13px;
  }

  .btn {
    margin-top: 35px;
  }

  .divider{
    width: 100%;
    margin-top: 18px;
    margin-bottom: 21px;
  }

  .plan-price{
    sup{
      font-size: 35%;
      top: -1.2em;
      &.big{
        font-size: 60%;
        top: -0.6em;
      }
    }
    sub{
      font-size: 35%;
      bottom: 0;
    }
  }

  .list, .list-separated {
    span + span {
      margin-left: 6px;
    }
  }

  .box-planning-body {
    padding: 11% 8% 8% 8%;
    border: 1px solid $gray-lighter;
    border-top: none;
  }

  &.active {
    .box-planning-label {
      display: block;
    }
  }
}

// Box planning type 1
// -------------------------

.box-planning-type-1 {
  max-width: $box-planning-type-1-max-width;
  overflow: hidden;

  .box-planning-label {
    position: absolute;
    top: -35px;
    right: -35px;
    font-size: $box-planning-type-1-label-size;
    font-weight: $box-planning-type-1-label-weight;
    line-height: $box-planning-type-1-label-line-height;
    text-transform: uppercase;
    letter-spacing: .119em;

    > span {
      right: 32px;
      top: 48px;
      @include transform(rotate(47deg));
    }

    .box-planning-svg {
      width: $box-planning-type-1-label-width;
      height: $box-planning-type-1-label-height;
      fill: $box-planning-type-1-label-fill;
    }
  }

  &.active {
    box-shadow: $box-planning-type-1-active-box-shadow;

    .h1 {
      color: $box-planning-type-1-active-title-color;
      background: -webkit-linear-gradient(0deg, $brand-primary 0%, $brand-dollar-bill 100%); /* safari5.1+,chrome10+ */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

// Box planning type 2
// -------------------------

.box-planning-type-2 {
  max-width: $box-planning-type-2-max-width;

  .box-planning-label {
    position: absolute;
    top: 25px;
    right: -17px;

    span {
      right: 10px;
      top: 10px;
      @include transform(rotate(-27deg));
    }

    .box-planning-svg {
      width: 75px;
      height: 75px;
    }
  }

  &.active {
    box-shadow: $box-planning-type-2-active-box-shadow;
  }
}

// Box planning type 1, 2
// -------------------------

.box-planning-type-1,
.box-planning-type-2 {
  border: 1px solid $gray-lighter;
}

// Box planning type 3,4
// -------------------------

.box-planning-type-3, .box-planning-type-4 {
  max-width: 295px;
  padding: 0;

  .box-planning-header {
    padding: 11%;

    h2, .h2 {
      margin-bottom: 0;
    }
    h3, .h3 {
      margin-top: 5px;
      margin-bottom: 7px;
    }
  }

  .btn {
    margin-top: 25px;
  }

  .btn-default {
    @include button-variant-custom($white, $gray-darker, $gray-darker, $white, $brand-primary, $brand-primary);
  }
}

// Box planning type 4
// -------------------------

.box-planning-type-4 {
  max-width: $box-planning-type-4-max-width;
}


// Media
// -------------------------

@media (min-width: $screen-md-min) {

  // Box planning type 1, 2
  .box-planning-type-1,
  .box-planning-type-2{
    border: none;
  }
}
// Typography

// [converter] $parent hack
@mixin text-emphasis-variant($parent, $color) {
  blockquote.quote #{$parent},
  #{$parent} {
    color: $color;
  }

  a#{$parent}:hover,
  a#{$parent}:focus {
    color: darken($color, 10%);
  }
}

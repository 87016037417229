//
// Labels
// --------------------------------------------------

.label {
  padding: $label-padding;
}

// Labels styles
// --------------------------------------------------

.label-custom {
  display: inline-block;
  padding: $label-custom-padding;
  font-size: $label-custom-size;
  font-weight: $label-custom-weight;
  line-height: $label-custom-line-height;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: .06em;
  transition: .3s;

  h1 &, h2 &, h3 &, h4 &, h5 &, h6 &, p &{
    margin-left: $label-custom-title-offset-left;
  }

  &,&:hover,&:focus{
    color: $label-custom-hover;
  }
}

.label-ellipsis-custom {
  border-radius: $label-ellipsis-custom-border-radius;
}

.label-rounded-custom {
  border-radius: $label-rounded-custom;
}

// Labels sizes
// --------------------------------------------------

.label-xs-custom {
  padding: $label-xs-custom-padding;
}

.label-sm-custom {
  padding: $label-sm-custom-padding;
}

.label-lg-custom {
  font-size: $label-lg-custom-size;
  padding: $label-lg-custom-padding;
}

.label-xl-custom {
  font-size: $label-xl-custom-size;
  padding: $label-xl-custom-padding;
}


// Labels colors
// --------------------------------------------------

.label-deluge{
  @include label-variant($label-deluge-bg);
}

.label-default {
  @include label-variant($label-default-bg);
}

.label-primary {
  @include label-variant($label-primary-bg);
}

.label-success {
  @include label-variant($label-success-bg);
}

.label-info {
  @include label-variant($label-info-bg);
}

.label-warning {
  @include label-variant($label-warning-bg);
}

.label-danger {
  @include label-variant($label-danger-bg);
}


//
// Box icons
// --------------------------------------------------

// Base styles
// -------------------------

.box-icon {
  position: relative;
  padding: $box-icon-padding;
}

// Box sizes
// -------------------------

.box-icon-sm {
  padding: $box-icon-sm-padding;
}
.box-icon-lg{
  padding: $box-icon-lg-padding;
}

// Box styles
// --------------------------

.box-icon-bordered{
  border: $box-icon-bordered-border;
}

// Icon styles
//---------------------------

.box-icon {
  .icon {
    position: absolute;
    top: 0;
    left: 50%;
    @include transform(translate(-50%, -50%));
  }
}

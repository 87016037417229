//
// Custom Alert
// --------------------------------------------------


// Base styles
// --------------------------------------------------

// Alert Classic
// -------------------------

.alert-classic {
  padding: $alert-classic-padding;
  margin-top: $alert-classic-offset-top;
  border-left-width: $alert-classic-border-left-width;
  border-left-style: $alert-classic-border-left-style;
  text-align: left;
  background-color: $alert-classic-background-color;

  h1, h2, h3, h4, h5, h6 {
    font-weight: $alert-classic-title-weight;
  }
}

// Snackbars
// -------------------------

.snackbars {
  max-width: $snackbars-max-width;
  padding: $snackbars-padding;
  margin-top: $snackbars-offset-top;
  margin-left: $snackbars-offset-left;
  margin-right: $snackbars-offset-right;
  color: $snackbars-color;
  text-align: left;
  background-color: $snackbars-background-color;
  border-radius: $snackbars-border-radius;
  box-shadow: $snackbars-box-shadow;

  .icon-xxs {
    font-size: $snackbars-icon-size;
  }

  // padding on text
  p span:last-child {
    padding-left: $snackbars-padding-from-icon-to-text;
  }

  // snackbar icon on the left side
  &-left {
    display: inline-block;
    margin-bottom: 0;
  }

  // snackbar link or button on the right side
  &-right {
    display: inline-block;
    float: right;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: $screen-xs) {
    max-width: $snackbars-xs-max-width;
    padding: $snackbars-xs-padding;
  }
}

// Bootstrap Alert
// -------------------------

.alert {
  margin-top: 10px;
  margin-bottom: 0;
  text-align: left;

  // bootstrap close button
  .close {
    top: 0;
    right: -9px;
    opacity: 1;
    text-shadow: none;
    outline: none;

    &:focus, &:hover {
      color: $white;
      opacity: 1;
    }
  }
}

// Sizes
// -------------------------

.alert-classic-lg {
  padding-top: $alert-classic-lg-padding-top;
  padding-bottom: $alert-classic-lg-padding-bottom;
  border-left-width: $alert-classic-lg-border-left-width;

  h4, .icon {
    font-size: 21px;
  }

  .btn-sm {
    padding: 7px 15px;
    min-width: 80px;
    border-width: 1px;
  }

  @media (min-width: $screen-sm-min) {
    h4 {
      font-size: $font-size-h4;
    }

    .icon {
      font-size: 30px;
    }
  }
}

.alert-classic-sm {
  padding-top: $alert-classic-sm-padding-top;
  padding-bottom: $alert-classic-sm-padding-bottom;
  border-left-width: $alert-classic-sm-border-left-width;
}


// Alternate styles
// --------------------------------------------------
// Generate contextual modifier classes for colorizing the alert.

// Alert Classic

.alert-classic-danger {
  border-left-color: $alert-classic-danger-border-left-color;

  h1, h2, h3, h4, h5, h6 {
    color: $alert-classic-danger-title-color;
  }
}

.alert-classic-info {
  border-left-color: $alert-classic-info-border-left-color;

  h1, h2, h3, h4, h5, h6 {
    color: $alert-classic-info-title-color;
  }
}

.alert-classic-success {
  border-left-color: $alert-classic-success-border-left-color;

  h1, h2, h3, h4, h5, h6 {
    color: $alert-classic-success-title-color;
  }
}

.alert-classic-warning {
  border-left-color: $alert-classic-warning-border-left-color;

  h1, h2, h3, h4, h5, h6 {
    color: $alert-classic-warning-title-color;
  }
}

// Bootstrap
// -------------------------

.alert-success {
  @include alert-variant($brand-success, $brand-success, $white);
}

.alert-info {
  @include alert-variant($brand-info, $brand-info, $white);
}

.alert-warning {
  @include alert-variant($brand-warning, $brand-warning, $white);
}

.alert-danger {
  @include alert-variant($brand-danger, $brand-danger, $white);
}
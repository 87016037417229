/*
* @subsection   RD Material Tabs
*
* @description  Describes style declarations for RD Material Tabs extension
*/

.rd-material-tabs {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  position: relative;

  &__list {
    white-space: nowrap;
    ul {
      @include list-unstyled;
    }

    .rd-material-tab {
      display: inline-block;
    }
  }

  img {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  &__stage-outer {
    position: relative;
    overflow: hidden;
  }

  &__stage {
    @include clearfix;
    transition: all 0.5s cubic-bezier(0.005, 0.300, 0.200, 1.000);
  }
}

.rd-material-tabs__container {
  padding: 0 5px;
  margin-top: 60px;
  .rd-material-tab {
    float: left;
    width: 100%;
  }
  .thumb {
    margin-top: 10px;
  }

}

.rd-material-tabs-canMove {
  cursor: w-resize;
}

.rd-material-tabs-grab, .rd-material-tabs-grab a {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

//
// Checkout Material Tabs
// --------------------------------------------------
.checkout-tabs {
  .rd-material-tabs__list {
    .rd-material-tab {
      a {
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        padding: 10px 13px;
        transition: .3s all ease;
        border-radius: $border-radius-base;
        &:hover {
          color: $brand-primary;
        }
      }
      @media (min-width: $screen-sm) {
        + .rd-material-tab {
          margin-left: 6px;
        }
      }

      &-active a {
        background: $brand-primary;
        color: $white;
        &:hover {
          color: $white;
        }
      }

    }
  }
}

//
// Gallery Material Tabs
// --------------------------------------------------
.gallery-tabs {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  position: relative;
  opacity: 0;
  visibility: hidden;
  &.loaded {
    opacity: 1;
    visibility: visible;
  }
  .rd-material-tabs {
    &__list {
      .rd-material-tab {
        a {
          text-transform: uppercase;
          transition: .3s all ease;
          font-size: 14px;
          line-height: 20px;
          color: $gray-dark;
        }
        &.rd-material-tab-active a, .desktop & a:hover {
          color: $white;
        }
        @media (min-width: $screen-md-min) {
            a{
              font-size: 20px;
              letter-spacing: 2px;
              font-weight: 500;
              text-align: center;
            }
        }
      }
    }

  }
  .rd-material-tabs__container {
    margin-top: 0;
    padding: 0;
    .image {
      opacity: 0;
      transition: 0.5s opacity ease;
      &.visible {
        opacity: 1;
        transition: 1s opacity ease;
      }
    }
  }
}

.tablet,
.mobile {
  .gallery-tabs {
    opacity: 1;
    visibility: visible;
  }
  .rd-material-tabs__container {
    .image {
      opacity: 1;
    }
  }
}

//
// Indent Utilities
// --------------------------------------------------

@mixin indent-responsive($preffix, $postfix, $rules, $medias, $offsets) {
  @if ($postfix != '' and $postfix != null) {
    $postfix: #{$postfix}-;
  }

  @if ($preffix != '' and $preffix != null) {
    $preffix: #{$preffix}-;
  }

  @each $resolution, $alias in $medias {
    @if ($resolution == 0) {
      @each $offset in $offsets {
        .#{$preffix}#{$postfix}#{strip-unit($offset)} {
          @each $rule in $rules {
            #{$rule}: $offset;
          }
        }
      }
    } @else {
      @media (min-width: $resolution) {
        @each $offset in $offsets {
          .#{$preffix}#{$alias}-#{$postfix}#{strip-unit($offset)} {
            @each $rule in $rules {
              #{$rule}: $offset;
            }
          }
        }
      }
    }
  }
}


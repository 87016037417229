//
// Rules Utilities
// --------------------------------------------------

@mixin rules-responsive($preffix, $medias, $postfix) {
  @if ($preffix != '' and $preffix != null) {
    $preffix: #{$preffix}-;
  }

  @each $resolution, $alias in $medias {
    @if ($resolution == 0) {
      @each $offset in $offsets {
        .#{$preffix}#{$postfix} {
          @content;
        }
      }
    } @else {
      @media (min-width: $resolution) {
        @each $offset in $offsets {
          .#{$preffix}#{$alias}-#{$postfix} {
            @content;
          }
        }
      }
    }
  }
}

/**
* Template Style
*
* [Table of contents]
*   1 Bootstrap Framework
*     1.1  Normalize
*     1.2  Glyphicons
*     1.3  Scaffolding
*     1.4  Type
*     1.5  Code
*     1.6  Tables
*     1.7  Forms
*     1.8  Buttons
*     1.9  Grids
*     1.10 Component animations
*     1.11 Dropdowns
*     1.12 Button Groups
*     1.13 Input Groups
*     1.14 Navs
*     1.15 Navbar
*     1.16 Breadcrumbs
*     1.17 Pagination
*     1.18 Pager
*     1.19 Labels
*     1.20 Badges
*     1.21 Jumbotron
*     1.22 Thumbnails
*     1.23 Alerts
*     1.24 Progress bars
*     1.25 Media
*     1.26 List Group
*     1.27 Panels
*     1.28 Responsive Embed
*     1.29 Wells
*     1.30 Close
*     1.31 Modals
*     1.32 Tooltip
*     1.33 Popovers
*     1.34 Carousel
*     1.35 Utilities
*     1.36 Responsive Utilities
*
*   2 Bootstrap Toolkit Styles
*     2.1  Reset
*     2.2  Flex Grid System
*     2.3  Responsive Text System
*     2.4  Responsive Pulls System
*     2.5  Responsive Visibility System
*     2.6  Bootstrap Grid Addons
*     2.7  Dividers
*     2.8  Type Addons
*     2.9  Buttons Addons
*     2.10 Buttons Effects
*     2.11 Dropdowns Addons
*     2.12 Sections
*     2.13 Font Awesome
*     2.14 Flaticon
*     2.15 Material Design Icons
*     2.16 Thumbnail Addons
*     2.17 Wells Addons
*     2.18 Scaffolding Addons
*     2.19 Text Alignment System
*     2.20 Text Styling System
*     2.21 Navs Addons
*     2.22 Navbar Addons
*     2.23 Panels Addons
*     2.24 Icons
*     2.25 Progress Bars Addons
*     2.26 Media Addons
*     2.27 List Groups Addons
*     2.28 Breadcrumbs Addons
*     2.29 Input Groups Addons
*     2.30 Dropcaps
*     2.31 Alerts Addons
*     2.32 Animations Addons
*     2.33 Groups
*     2.34 Comment Boxes
*     2.35 Quotes
*     2.36 Pagination Addons
*     2.37 Pager Addons
*     2.38 Labels Addons
*     2.39 Posts
*     2.40 Form Addons
*     2.41 Offsets
*     2.42 Responsive Units
*     2.43 Icon Boxes
*     2.44 Floating Groups
*     2.45 Pricing and Plans
*     2.46 Tables Addons
*     2.47 Stacktables
*     2.48 Text Rotators
*     2.49 Jumbotron Addons
*     2.50 Shop Toolbar
*     2.51 Shop Product
*     2.52 Badges Addons
*     2.53 Member Boxes
*     2.54 Context Styling
*     2.55 Page Loaders
*
*   3 Plugins
*     3.1  RD Navbar
*     3.2  RD Twitterfeed
*     3.3  RD Search
*     3.4  RD Video
*     3.5  RD Parallax
*     3.6  RD Flickrfeed
*     3.7  Swiper Slider
*     3.8  Animate
*     3.9 Scroll To
*     3.10 Counter
*     3.11 Owl Carousel
*     3.12 Isotope
*     3.13 Photoswipe
*     3.14 Responsive Tabs
*     3.15 RD Google Maps
*     3.16 Select 2
*     3.17 FS Stepper
*     3.18 Countdown
*     3.19 RD Audio Player
*     3.20 RD Video Player
*     3.21 RD Event Calendar
*     3.22 RD Facebook Feed
*     3.23 Soundcloud Player
*     3.24 Material Datetime Picker
*     3.25 ToTop Button
*     3.26 RD Material Tabs
*     3.27 C3 Charts
*     3.28 Flot Charts
*     3.29 Google ReCaptcha
*     3.30 Revolution slider
*/

@import "bootstrap/mixins";
// Core CSS
/** 1 Bootstrap Framework */

/** 1.1  Normalize */

// Reset and dependencies
@import "bootstrap/normalize";
@import "bootstrap/print";

/** 1.2  Glyphicons */
@import "bootstrap/glyphicons";

/** 1.3  Scaffolding */
@import "bootstrap/scaffolding";

/** 1.4  Type */
@import "bootstrap/type";

/** 1.5  Code */
@import "bootstrap/code";

/** 1.6  Tables */
@import "bootstrap/tables";

/** 1.7  Forms */
@import "bootstrap/forms";

/** 1.8  Buttons */
@import "bootstrap/buttons";

/** 1.9  Grids */
@import "bootstrap/grid";


// Components


/** 1.10 Component animations */
@import "bootstrap/component-animations";

/** 1.11 Dropdowns */
@import "bootstrap/dropdowns";

/** 1.12 Button Groups */
@import "bootstrap/button-groups";

/** 1.13 Input Groups */
@import "bootstrap/input-groups";

/** 1.14 Navs */
@import "bootstrap/navs";

/** 1.15 Navbar */
@import "bootstrap/navbar";

/** 1.16 Breadcrumbs */
@import "bootstrap/breadcrumbs";

/** 1.17 Pagination */
@import "bootstrap/pagination";

/** 1.18 Pager */
@import "bootstrap/pager";

/** 1.19 Labels */
@import "bootstrap/labels";

/** 1.20 Badges */
@import "bootstrap/badges";

/** 1.21 Jumbotron */
@import "bootstrap/jumbotron";

/** 1.22 Thumbnails */
@import "bootstrap/thumbnails";

/** 1.23 Alerts */
@import "bootstrap/alerts";

/** 1.24 Progress bars */
@import "bootstrap/progress-bars";

/** 1.25 Media */
@import "bootstrap/media";

/** 1.26 List Group */
@import "bootstrap/list-group";

/** 1.27 Panels */
@import "bootstrap/panels";

/** 1.28 Responsive Embed */
@import "bootstrap/responsive-embed";

/** 1.29 Wells */
@import "bootstrap/wells";

/** 1.30 Close */
@import "bootstrap/close";

// Components w/ JavaScript
/** 1.31 Modals */
@import "bootstrap/modals";

/** 1.32 Tooltip */
@import "bootstrap/tooltip";

/** 1.33 Popovers */
@import "bootstrap/popovers";

/** 1.34 Carousel */
@import "bootstrap/carousel";

// Utility classes
/** 1.35 Utilities */
@import "bootstrap/utilities";

/** 1.36 Responsive Utilities */
@import "bootstrap/responsive-utilities";


/** 2 Bootstrap Toolkit Styles */


@import "custom/mixins-custom";

// Additional Style Reset
/** 2.1  Reset */
@import "custom/reset";

// Toolkit Core
/** 2.2  Flex Grid System */
@import "custom/flex-grid";

/** 2.3  Responsive Text System */
@import "custom/text-responsive";

/** 2.4  Responsive Pulls System */
@import "custom/pull-responsive";

/** 2.5  Responsive Visibility System */
@import "custom/visibility-responsive";

// Toolkit Components
/** 2.6  Bootstrap Grid Addons */
@import "custom/grid-custom";

/** 2.7  Dividers*/
@import "custom/dividers";

/** 2.8  Type Addons */
@import "custom/type-custom";

/** 2.9  Buttons Addons */
@import "custom/buttons-custom";

/** 2.10 Buttons Effects */
@import "custom/buttons-effects";

/** 2.11 Dropdowns Addons */
@import "custom/dropdowns-custom";

/** 2.12 Sections */
@import "custom/sections";

/** 2.13 Font Awesome */
@import "custom/font-awesome";

/** 2.14 Flaticon */
@import "custom/flaticon";

/** 2.15 Material Design Icons */
@import "custom/materialdesignicons";

/** 2.16 Thumbnail Addons */
@import "custom/thumbnails-custom";

/** 2.17 Wells Addons */
@import "custom/wells-custom";

/** 2.18 Scaffolding Addons */
@import "custom/scaffolding-custom";

/** 2.19 Text Alignment System */
@import "custom/text-alignment";

/** 2.20 Text Styling System */
@import "custom/text-styling";

/** 2.21 Navs Addons */
@import "custom/navs-custom";

/** 2.22 Navbar Addons */
@import "custom/navbar-custom";

/** 2.23 Panels Addons */
@import "custom/panels-custom";

/** 2.24 Icons */
@import "custom/icons";

/** 2.25 Progress Bars Addons */
@import "custom/progress-bars-custom";

/** 2.26 Media Addons */
@import "custom/media-custom";

/** 2.27 List Groups Addons */
@import "custom/list-group-custom";

/** 2.28 Breadcrumbs Addons */
@import "custom/breadcrumb-custom";

/** 2.29 Input Groups Addons */
@import "custom/input-groups-custom";

/** 2.30 Dropcaps */
@import "custom/dropcaps";

/** 2.31 Alerts Addons */
@import "custom/alerts-custom";

/** 2.32 Animations Addons */
@import "custom/animations-custom";

/** 2.33 Groups */
@import "custom/groups";

/** 2.34 Comment Boxes */
@import "custom/box-comment";

/** 2.35 Quotes */
@import "custom/quotes";

/** 2.36 Pagination Addons */
@import "custom/pagination-custom";

/** 2.37 Pager Addons */
@import "custom/pager-custom";

/** 2.38 Labels Addons */
@import "custom/labels-custom";

/** 2.39 Posts */
@import "custom/posts";

/** 2.40 Form Addons */
@import "custom/forms-custom";

/** 2.41 Offsets */
@import "custom/offsets";

/** 2.42 Responsive Units */
@import "custom/unit-responsive";

/** 2.43 Icon Boxes */
@import "custom/box-icons";

/** 2.44 Floating Groups */
@import "custom/floating-group";

/** 2.45 Pricing and Plans */
@import "custom/pricing-plans";

/** 2.46 Tables Addons */
@import "custom/tables-custom";

/** 2.47 Stacktables */
@import "custom/stacktable";

/** 2.48 Text Rotators */
@import "custom/text-rotator";

/** 2.49 Jumbotron Addons */
@import "custom/jumbotron-custom";

/** 2.50 Shop Toolbar */
@import "custom/toolbar-shop";

/** 2.51 Shop Product */
@import "custom/product";

/** 2.52 Badges Addons */
@import "custom/badges-custom";

/** 2.53 Member Boxes */
@import "custom/box-member";

/** 2.54 Context Styling */
@import "custom/context-styling";

/** 2.55 Page Loaders */
@import "custom/page-loaders";


/** 3 Plugins */


// RD Navbar
/** 3.1  RD Navbar */
@import "plugins/rd-navbar_includes/rd-navbar-mixins";
@import "plugins/rd-navbar";
@import "plugins/rd-navbar_includes/_rd-navbar-default-panel";
@import "plugins/rd-navbar_includes/_rd-navbar-sidebar-toggle-panel";
@import "plugins/rd-navbar_includes/_rd-navbar-default-cart";
@import "plugins/rd-navbar_includes/_rd-navbar-default-aside-left";
@import "plugins/rd-navbar_includes/_rd-navbar-default-mobile-menu";
@import "plugins/rd-navbar_includes/_rd-navbar-default-cart-aside-right";
@import "plugins/rd-navbar_includes/_rd-navbar-default-mobile-search";
@import "plugins/rd-navbar_includes/_rd-navbar-default-menu-first-lvl";
@import "plugins/rd-navbar_includes/_rd-navbar-default-menu-dropdown";
@import "plugins/rd-navbar_includes/_rd-navbar-default-desktop-cart";
@import "plugins/rd-navbar_includes/_rd-navbar-default-search";
@import "plugins/rd-navbar_includes/_rd-navbar-sidebar-toggle-footer";
@import "plugins/rd-navbar_includes/_rd-navbar-sidebar-fixed-panel";
@import "plugins/rd-navbar_includes/_rd-navbar-sidebar-fixed-menu-wrap";
@import "plugins/rd-navbar_includes/_rd-navbar-sidebar-fixed-search";
@import "plugins/rd-navbar_includes/_rd-navbar-top-panel-top";
@import "plugins/rd-navbar_includes/_rd-navbar-logo-center-menu-first-lvl";
@import "plugins/rd-navbar-default";
@import "plugins/rd-navbar-sidebar-toggle";
@import "plugins/rd-navbar-sidebar-fixed";
@import "plugins/rd-navbar-floated";
@import "plugins/rd-navbar-top-panel";
@import "plugins/rd-navbar-logo-center";

/** 3.2  RD Twitterfeed */
@import "plugins/rd-twitter";

/** 3.2  RD Search */
@import "plugins/rd-search";

/** 3.4  RD Video */
@import "plugins/rd-video";

/** 3.5  RD Parallax */
@import "plugins/rd-parallax";

/** 3.6  RD Flickrfeed */
@import "plugins/rd-flickrfeed";

/** 3.7  Swiper Slider */
@import "plugins/swiper";

/** 3.8  Animate */
@import "plugins/animate";

/** 3.9 Scroll To */
@import "plugins/scroll-to";

/** 3.10 Count To */
@import "plugins/counter";

/** 3.11 Owl Carousel */
@import "plugins/owl-carousel";

/** 3.12 Isotope */
@import "plugins/isotope";

/** 3.13 Photoswipe */
@import "plugins/photoswipe";

/** 3.14 Responsive Tabs */
@import "plugins/_responsive-tabs";

/** 3.15 RD Google Maps */
@import "plugins/_rd-google-map.scss";

/** 3.16 Select 2 */
@import "plugins/_select2";

/** 3.17 FS Stepper */
@import "plugins/_jquery.fs.stepper";

/** 3.18 Countdown */
@import "plugins/_countdown";

/** 3.19 RD Audio Player */
@import "plugins/_rd-audio";

/** 3.20 RD Video Player */
@import "plugins/_rd-video-player";

/** 3.21 RD Event Calendar */
@import "plugins/_rd-calendar";

/** 3.22 RD Facebook Feed */
@import "plugins/_rd-facebook";

/** 3.23 Soundcloud Player */
@import "plugins/_soundcloud";

/** 3.24 Material Datetime Picker */
@import "plugins/_bootstrap-material-datetimepicker";

/** 3.25 ToTop Button */
@import "plugins/_ui-to-top";

/** 3.26 RD Material Tabs */
@import "plugins/_rd-material-tabs";

/** 3.27 C3charts */
@import "plugins/c3-charts";

/** 3.28 FlotCharts */
@import "plugins/flot-charts";

/** 3.29 Google ReCaptcha */
@import "plugins/_recaptcha";


/** 3.30 Revolution slider */
@import "plugins/_revolution-slider";


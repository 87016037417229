// Form validation states
//
@mixin form-control-validation-custom($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
  // Color the label and help text
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label {
    color: $text-color;
  }

  // Set the border and box shadow on specific inputs to match
  .form-control:not(.form-control-impressed) {
    &, &:focus {
      border-color: $border-color;
      box-shadow: none;
    }
  }

  .form-control-impressed{
    &, &:focus{
      box-shadow: inset 0 0 0 1px $border-color;
    }
  }

  // Set validation states also for addons
  .input-group-addon {
    color: $white;
    border-color: $border-color;
    background-color: $background-color;
  }

  // Set validation states also for buttons
  .input-group-btn{
    > .btn{
      border-color: $border-color;
      background: $background-color;
    }
  }

  // Set validation states also for validation message
  .form-validation{
    color: $text-color;
  }
}